$black: #000;
$debug: #ccc;
$error: #d9141e;
$info: #cab999;
$success: #32c518;
$warning: #f6d128;
$white: #fff;

ul.messagelist {
    list-style-type: none;
    margin: auto;
    max-width: 1400px;
    padding: 0;
    position: relative;
    top: 91px;

    li {
        border-radius: 8px;
        border: 1px solid #cfdbe9;
        margin: 2rem 0;
        padding: 1rem;


        &.debug {
            color: $black;
        }

        &.info {
            border-color: $info;
        }

        &.warning {
            border-color: $warning;
        }

        &.success {
            border-color: $success;
        }

        &.error {
            border-color: $error;
        }

        > p {
            margin: 0;
        }
    }
}

@media (max-width: 574px) {
    ul.messagelist {
        font-size: 110%;

        li {
            padding: 0.2em 0.8em;
        }
    }
}
