@use './../bootstrap/variables' as *;

// nav desktop all pages
.page-navigation {
    font-family: 'Inter-Regular', sans-serif;

    &.container {
        @media (min-width: 992px) {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            justify-content: right;
            margin: 0;
            max-width: 100%;
            padding: 0;
        }
    }

    li {
        display: inline-block;
        margin: 0;
        padding: 0;

        @media (min-width: 992px) {
            margin: 0 1rem;
        }

        &:last-of-type {
            margin-right: 0;
        }


        @media (min-width: 992px) {
            &.children > a::after {
                content: url('../img/arrow.svg');
                display: block;
                position: absolute;
                right: -1.5rem;
                top: 30%;
                transform: rotate(180deg);
            }

            &.children > a:hover::after {
                content: url('../img/arrow_hover.svg');
            }
        }
    }

    a {
        color: $dark;
        cursor: pointer;
        display: inline-block;
        font-size: 1.1rem;
        margin: 0 0.9em;
        padding: 0.8rem 1px;
        position: relative;
        text-decoration: none;
        transition: all 0.15s ease-in-out;

        &:hover,
        &:focus {
            color: #667995;
        }
    }

    .item-btn a {
        margin-right: 0;
        padding: 0.62rem 1.5rem;
    }

    .undermenu {
        background-color: $white;
        border-radius: 3px;
        box-shadow: 0 6px 10px 0 rgba(139, 139, 139, 0.5);
        opacity: 0;
        padding: 15px 0;
        position: absolute;
        transform: translate(-9px, 11px);
        transition: 0.15s ease-in-out;
        visibility: hidden;
        z-index: 102;

        @media (min-width: 992px) {
            border: 1px solid $border;
            min-width: 260px;
        }

        li {
            display: block;
            margin: 0;
            padding: 0;

            a {
                background-position: 23px center;
                background-repeat: no-repeat;
                background-size: auto 26px;
                color: $dark;
                display: block;
                line-height: 1;
                margin: 0;
                padding: 8px 20px;
                transition: all 0.15s ease-in-out;
                width: 100%;

                &:hover {
                    background-color: $border;
                }
            }
        }

        @media (min-width: 992px) {

            &::after,
            &::before {
                border: solid transparent;
                bottom: 100%;
                content: '';
                height: 0;
                left: 2.1rem;
                pointer-events: none;
                position: absolute;
                width: 0;
            }

            &::after {
                border-color: transparent;
                border-bottom-color: $white;
                border-width: 6.4px;
                margin-left: -6.4px;
            }

            &::before {
                border-color: transparent;
                border-bottom-color: $border;
                border-width: 8px;
                margin-left: -8px;
            }
        }
    }

    li:hover .undermenu,
    li:focus .undermenu {
        opacity: 1;
        visibility: visible;
    }
}
