@use './../bootstrap/variables' as *;

.pagination {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-top: 0.5rem;

    li {
        list-style-type: none;
        margin: 10px;

        span,
        a {
            border: 1px solid transparent;
            border-radius: 0.5rem;
            color: #709db0;
            font-size: 1rem;
            padding: 8px 16px;
            line-height: 1.5;
            transition: all 0.15s ease-in-out;
        }

        & > a {

            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }

        &.active {
            span {
                border: 1px solid $border;
                color: $dark;
                text-decoration: none;
            }
        }
    }

    .badge {
        display: none;
    }

    .previous-page,
    .next-page {

        span,
        a {

            border: 1px solid $border;

            &:hover,
            &:focus {
                background-color: #e5feff;
                text-decoration: none;
            }
        }
    }
}


.djangocms-newsblog-article-detail {
    .pagination {
        align-items: center;
        display: flex;
        flex-direction: column;
        padding: 0;
        padding-top: 50px;

        li {
            list-style-type: none;
            padding: 1rem;
        }

        li.previous-page a::before {
            content: "←";
            left: -0.2em;
            position: relative;
        }

        li.next-page a::after {
            content: "→";
            position: relative;
            right: -0.2em;
        }

        li.back-to-overview {
            &::before {
                content: "–";
                left: -0.2em;
                position: relative;
            }

            &::after {
                content: "–";
                position: relative;
                right: -0.2em;
            }
        }
    }
}
