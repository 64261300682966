@use './../bootstrap/variables' as *;

/*
*
* aldryn-newsblog-pagination, pagenav
*
*/
.aldryn-newsblog-pagination,
.pagenav {
    ul {
        li {
            display: inline-block;

            a {
                border: 1px solid $border;
                display: inline-block;
                line-height: 1.55rem;
                min-width: 1.55rem;
                padding: 0.2rem 0.4rem;

                &:hover {
                    border: 1px solid $error;
                    color: $error;
                }
            }
        }

        li.active span {
            display: inline-block;
            line-height: 1.5rem;
            min-width: 1.55rem;
            text-align: center;
        }
    }
}
