@use './../bootstrap/variables' as *;

main,
footer {
    overflow: hidden;
}

@media (min-width: 1450px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 1400px;
    }
}

.row {
    > * {
        @media (min-width: 1900px) {
            padding-left: 1.07rem;
            padding-right: 1.07rem;
        }
    }

    .same-height {
        padding-bottom: 1.5rem;

        @media (min-width: 1900px) {
            padding-bottom: 2.14rem;
        }
    }
}

figure {
    margin: 0;
}

.mw-100-img img {
    max-width: 100%;
}

small {
    font-size: 11px;
}

p a,
ul a,
ol a {
    text-decoration: underline;
}

.button-295s {
    width: 100%;

    @media (min-width: 768px) {
        min-width: 295px;
    }
}

.button-more-with {
    width: 100%;

    @media (min-width: 768px) {
        width: 385px;
    }
}

.mb-n-0 * {
    margin-bottom: 0;
}

.lang {
    text-transform: uppercase;

    &.active {
        display: none;
    }
}

.font-400-inter,
.font-400-inter * {
    font-family: Inter-Regular, sans-serif;
    font-weight: unset;
}

strong,
.font-700-inter,
.font-700-inter * {
    font-family: Inter-Bold, sans-serif;
    font-weight: unset;
}

.w-100-img img {
    width: 100%;
}

.wm-100-img img {
    max-width: 100%;
}

.btn-xxl-width {
    min-width: 100%;

    @media (min-width: 567px) {
        min-width: 385px;
    }
}

.text-underline,
.text-underline * {
    text-decoration: underline;
}

table {
    font-size: 0.95rem;
    width: 100%;

    tr {
        border-bottom: 1px solid $info;
    }

    td {
        padding: 0.7rem 0;
    }
}

.response-img {
    img {
        height: 100%;
        object-fit: cover;
        width: 100%;
    }
}

.w-xxl-auto {
    @media (min-width: 1400px) {
        width: auto !important;
    }
}

.linear-gradient1 {
    background: linear-gradient(90deg, #00252e 41.75%, #006a82 87.83%);
}

.linear-gradient2 {
    background: linear-gradient(to top, $white 103px, $light 103px, $light 100%)
}

.pt-1px {
    padding-top: 1px;
}

.small-text,
.small-text * {
    font-size: 11px;
}

.z-index-2,
.z-index-2 * {
    position: relative;
    z-index: 2;
}

.latest-articles-w {
    @media (min-width: 567px) {
        width: 385px;
    }
}

.height-100 {
    height: 100px;
}

.gradinet-1 {
    background: linear-gradient(125deg, #1D2C42 41.75%, #003893 87.83%);
}


.search-form {
    input {
        font-family: Inter-Regular, sans-serif;
        padding: 0.45rem 1rem;

        @media (min-width: 992px) {
            max-width: 210px;
        }

        &:focus ~ button,
        &:hover ~ button {
            background-color: $border;
            border-color: $border;
        }

        &:active ~ button {
            background-color: $info;
            border-color: $info;
        }
    }

    button {
        border-left-width: 0;
        border-radius: 0 8px 8px 0 !important;
        padding-left: 1rem;
        padding-right: 1rem;
        position: relative;
        width: 52px;

        &::after {
            background: url('../img/search.svg') center center no-repeat;
            content: '';
            display: block;
            height: 16px;
            position: absolute;
            top: 11px;
            width: 16px;
        }
    }
}

ul.results-list {
    list-style-type: none;
    padding: 0;

    li {
        border: 1px solid $border;
        border-radius: 8px;
        margin: 2rem 0;
        padding: 1rem;
    }
}

table {
    border: 1px solid $border;

    td {
        border: 1px solid $border;
        vertical-align: inherit;
        padding: 0.7rem;
    }

    a {
        text-decoration: underline;
    }
}

.lh-1 * {
    line-height: 1;
}
