@use './../bootstrap/variables' as *;

// header-container
.header-container {
    height: 80px;
    transition: all 0.15s ease-in-out;
    width: 100%;

    > .row {
        height: 80px;
        margin: 0;
        padding: 0;

        @media (min-width: 992px) {
            height: auto;
        }
    }

    .col {
        padding: 0;
    }

    .header-mobile {
        width: 100%;

        @media (max-width: 991px) {
            padding: 15px 0;
        }
    }

    .logo {
        margin-left: 15px;
    }

    #toggleMenu {
        cursor: pointer;
        height: 50px;
        width: 50px;
    }

    @media (min-width: 576px) {
        max-width: 100%;

        .header-mobile {
            margin: auto;
            max-width: 540px;
        }
    }

    @media (min-width: 768px) {
        .header-mobile {
            max-width: 720px;
        }
    }

    @media (min-width: 992px) {
        height: auto;
        margin-left: auto;
        margin-right: auto;
        max-width: 960px;
        padding: 0;

        .header-mobile {
            margin-left: 0;
            max-width: 140px;
        }
    }

    @media (min-width: 1200px) {
        max-width: 1140px;
    }

    @media (min-width: 1400px) {
        max-width: 1320px;
    }

    @media (min-width: 1450px) {
        max-width: 1400px;
    }

    #aldryn-search {
        width: 80%;

        @media (min-width: 992px) {
            width: 100%;
        }

        .text-danger {
            animation: rotation 1s linear infinite;
            border: 3px solid $info;
            border-bottom-color: rgba(0, 0, 0, 0);
            border-radius: 50%;
            box-sizing: border-box;
            display: inline-block;
            font-size: 0;
            height: 25px;
            position: absolute;
            right: -32px;
            top: 6px;
            width: 25px;
        }
    }
}

.cms-form-success-message {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
