.btn-primary {
    @include button-variant( // bg
        $primary,
        // border-color
        $primary,
        // color
        $white,
        // hover:bg
        $info,
        // hover:border-color
        $info,
        // hover:color
        $white,
        // active:bg
        $title,
        // active:border-color
        $title,
        // active:color
        $white );
}

.btn-secondary {
    @include button-variant( // bg
        $white,
        // border-color
        $primary,
        // color
        $primary,
        // hover:bg
        $border,
        // hover:border-color
        $primary,
        // hover:color
        $dark,
        // active:bg
        $info,
        // active:border-color
        $primary,
        // active:color
        $white);
}

.btn-light,
.btn-info {
    @include button-variant( // bg
        $white,
        // border-color
        $white,
        // color
        $primary,
        // hover:bg
        $border,
        // hover:border-color
        $border,
        // hover:color
        $primary,
        // active:bg
        $info,
        // active:border-color
        $info,
        // active:color
        $white);
}

.btn-info {
    border-width: 2px;
}


.btn-warning {
    @include button-variant( // bg
        $white,
        // border-color
        $border,
        // color
        $primary,
        // hover:bg
        $border,
        // hover:border-color
        $border,
        // hover:color
        $primary,
        // active:bg
        $info,
        // active:border-color
        $info,
        // active:color
        $white);
}
